/* You can add global styles to this file, and also import other style files */
@import "colors";

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}


// body default styles for the entire app
body {
  background: #fcfcfc;
  font-family:'Roboto', Helvetica, Verdana, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #202020;
  position: relative;
  padding: 0;
  width: 100%;
}